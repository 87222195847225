import styled from 'styled-components'

import { DEVICE } from '../../../util/constants'
import { HeroH1, HeroP } from '../../styles/Utility.styles'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 32px repeat(10, 1fr) 32px;
  grid-template-rows: max-content max-content 27px auto;

  @media ${DEVICE.tabletL} {
    grid-template-columns: 1fr repeat(10, 1fr) 1fr;
    max-width: 1317px;
  }
`

export const Heading = styled(HeroH1)`
  grid-column: 2 / 12;
  grid-row: 1;
  padding: 25px 0 0;

  @media ${DEVICE.tabletL} {
    grid-column: 2 / 11;
  }

  @media ${DEVICE.laptopS} {
    grid-column: 2 / 11;
    padding: 50px 0 25px;
  }
`

export const Subheading = styled(HeroP)`
  grid-column: 2 / 12;
  margin: 0 0 25px;

  @media ${DEVICE.tabletL} {
    grid-column: 2 / 11;
  }

  @media ${DEVICE.laptopS} {
    grid-column: 2 / 11;
  }
`
